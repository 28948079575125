import { createContext, useEffect, useState, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import {
   CHURCHES_API,
   DOWNLOAD_API,
   PARENTS_API,
   PAYMENT_API,
   REGISTRATIONS_API,
   SCHOOL_CLASSES_API,
   SCHOOL_YEARS_API,
   SPONSORS_API,
   STUDENTS_API,
   TEACHERS_API,
   VOLUNTEERS_API,
} from '../../utils/constants';
import { saveAs } from 'file-saver';
import { TEducationContext } from '../../types/ReligiousEducation/EducationContext';
import { TStudentResponse } from '../../types/ReligiousEducation/Student';
import {
   TParents,
   TParentsResponse,
} from '../../types/ReligiousEducation/Parents';
import {
   TSchoolYear,
   TSchoolYearResponse,
} from '../../types/ReligiousEducation/SchoolYear';
import {
   TSchoolClass,
   TSchoolClassResponse,
} from '../../types/ReligiousEducation/SchoolClass';
import {
   TRegistration,
   TRegistrationResponse,
} from '../../types/ReligiousEducation/Registration';
import {
   TTeacher,
   TTeacherResponse,
} from '../../types/ReligiousEducation/Teacher';
import { TVolunteerResponse } from '../../types/ReligiousEducation/Volunteers';
import { TSponsorsResponse } from '../../types/ReligiousEducation/Sponsors';

const EducationContext = createContext<TEducationContext | null>(null);

export const EducationProvider = () => {
   //Loading States
   const [loading, setLoading] = useState(false);
   const [studentLoading, setStudentLoading] = useState(false);
   const [parentsLoading, setParentsLoading] = useState(false);
   const [schoolYearLoading, setSchoolYearLoading] = useState(false);
   const [schoolClassesLoading, setSchoolClassesLoading] = useState(false);
   const [registrationsLoading, setRegistrationsLoading] = useState(false);
   const [teachersLoading, setTeachersLoading] = useState(false);
   const [volunteersLoading, setVolunteersLoading] = useState(false);
   const [sponsorsLoading, setSponsorsLoading] = useState(false);

   //States
   const [students, setStudents] = useState<TStudentResponse[]>([]);
   const [parents, setParents] = useState<TParentsResponse[]>([]);
   const [currentSchoolYear, setCurrentYear] =
      useState<TSchoolYearResponse | null>(null);
   const [schoolYear, setSchoolYear] = useState<TSchoolYearResponse[]>([]);
   const [schoolClasses, setSchoolClasses] = useState<TSchoolClassResponse[]>(
      []
   );
   const [registrations, setRegistrations] = useState<TRegistrationResponse[]>(
      []
   );
   const [teachers, setTeachers] = useState<TTeacherResponse[]>([]);
   const [volunteers, setVolunteers] = useState<TVolunteerResponse[]>([]);
   const [sponsors, setSponsors] = useState<TSponsorsResponse[]>([]);

   const axiosPrivate = useAxiosPrivate();

   //Students
   const getStudents = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(STUDENTS_API);
         setStudents(data);
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const addStudent = async (formdata: FormData) => {
      try {
         setStudentLoading(true);
         const res = await axiosPrivate.post(STUDENTS_API, formdata, {
            headers: { 'Content-Type': 'multipart/form-data' },
         });
         setStudents(prev => [...prev, res.data]);
         setParents(prev =>
            prev.map(parent => {
               if (parent._id === res.data?.father) {
                  parent.students.push(res.data._id);
                  return parent;
               }
               return parent;
            })
         );
         setParents(prev =>
            prev.map(parent => {
               if (parent._id === res.data?.mother) {
                  parent.students.push(res.data._id);
                  return parent;
               }
               return parent;
            })
         );
         setParents(prev =>
            prev.map(parent => {
               if (parent._id === res.data?.legalGuardian) {
                  parent.students.push(res.data._id);
                  return parent;
               }
               return parent;
            })
         );
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setStudentLoading(false);
      }
   };
   const editStudent = async (id: string, formdata: FormData) => {
      if (!id) return { message: 'Student ID is required!' };
      try {
         setStudentLoading(true);
         const res = await axiosPrivate.patch(
            `${STUDENTS_API}/${id}`,
            formdata,
            {
               headers: { 'Content-Type': 'multipart/form-data' },
            }
         );
         setStudents(prev =>
            prev.map(student =>
               student._id === res.data._id ? res.data : student
            )
         );
         setRegistrations(prev =>
            prev.map(registration =>
               registration?.student?._id === res.data._id
                  ? { ...registration, student: res.data }
                  : registration
            )
         );
         return undefined;
      } catch (err: any) {
         if (!err.response)
            throw new Error(
               'No server response! Check your internet connection.'
            );
         throw new Error(
            err?.response?.data?.message ??
               'Failed to update student information, Please try again.'
         );
      } finally {
         setStudentLoading(false);
      }
   };

   const deleteStudentFile: TEducationContext['deleteStudentFile'] = async (
      id,
      publicId
   ) => {
      if (!id || !publicId) throw new Error('Invalid Request!');

      try {
         const res = await axiosPrivate.delete(`${STUDENTS_API}/${id}/file`, {
            params: { publicId },
         });

         setStudents(prev =>
            prev.map(student =>
               student._id === res.data._id ? res.data : student
            )
         );
         setRegistrations(prev =>
            prev.map(registration =>
               registration?.student?._id === res.data._id
                  ? { ...registration, student: res.data }
                  : registration
            )
         );
         return res.data;
      } catch (error: any) {
         throw new Error(
            error?.response?.data?.message ??
               'Failed to update student information, Please try again.'
         );
      }
   };

   //Parents
   const getParents = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(PARENTS_API);
         setParents(data);
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const addParent = async (data: TParents) => {
      try {
         setParentsLoading(true);
         const res = await axiosPrivate.post(PARENTS_API, data, {
            headers: { 'Content-Type': 'application/json' },
         });
         setParents(prev => [...prev, res.data]);
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setParentsLoading(false);
      }
   };
   const editParent = async (data: any) => {
      if (!data?._id) return { message: 'Parents ID is required!' };
      try {
         setParentsLoading(true);
         const res = await axiosPrivate.patch(
            `${PARENTS_API}/${data._id}`,
            data,
            {
               headers: { 'Content-Type': 'application/json' },
            }
         );
         setParents(prev =>
            prev.map(parent =>
               parent._id === res.data._id ? res.data : parent
            )
         );
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setParentsLoading(false);
      }
   };

   //School Year
   const getSchoolYears = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(SCHOOL_YEARS_API);
         setSchoolYear(data);
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const getCurrentSchoolYear = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(
            `${SCHOOL_YEARS_API}/current-year`
         );
         setCurrentYear(data);
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const addSchoolYear = async (data: TSchoolYear) => {
      try {
         setSchoolYearLoading(true);
         const res = await axiosPrivate.post(SCHOOL_YEARS_API, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
         });
         setSchoolYear(prev => [res.data, ...prev]);
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setSchoolYearLoading(false);
      }
   };
   const editSchoolYear = async (id: string, data: TSchoolYear) => {
      if (!id || !data) return {};
      try {
         setSchoolYearLoading(true);
         const res = await axiosPrivate.patch(
            `${SCHOOL_YEARS_API}/${id}`,
            data,
            {
               headers: { 'Content-Type': 'multipart/form-data' },
            }
         );
         setSchoolYear(prev =>
            prev.map(year => (year._id === res.data._id ? res.data : year))
         );
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setSchoolYearLoading(false);
      }
   };

   const getTuitionPaymentMethodStatus = async () => {
      const res = await axiosPrivate.get(
         `${PAYMENT_API}/connectedAccount/status`,
         {
            headers: { 'Content-Type': 'application/json' },
         }
      );
      return res.data;
   };
   const connectStripe = async () => {
      const res = await axiosPrivate.post(
         `${PAYMENT_API}/connectedAccount/connect`,
         { location: window.location.href }
      );
      return res.data;
   };

   //School Year
   const getClasses = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(SCHOOL_CLASSES_API);
         setSchoolClasses(data);
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const addSchoolClass = async (data: TSchoolClass) => {
      try {
         setSchoolClassesLoading(true);
         const res = await axiosPrivate.post(SCHOOL_CLASSES_API, data, {
            headers: { 'Content-Type': 'application/json' },
         });
         setSchoolClasses(prev => [...prev, res.data]);
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setSchoolClassesLoading(false);
      }
   };
   const editSchoolClass = async (data: TSchoolClass, id: string) => {
      if (!id) return;
      try {
         setSchoolClassesLoading(true);
         const res = await axiosPrivate.patch(
            `${SCHOOL_CLASSES_API}/${id}`,
            data,
            {
               headers: { 'Content-Type': 'application/json' },
            }
         );
         setSchoolClasses(existingClasses =>
            existingClasses.map(aClass =>
               aClass._id === id ? res.data : aClass
            )
         );
         return { data: res.data };
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { error: { message: err?.response?.data?.message || false } };
      } finally {
         setSchoolClassesLoading(false);
      }
   };
   const registrationsByClass: TEducationContext['registrationsByClass'] = ({
      classId,
      yearId,
   }) =>
      axiosPrivate
         .get(`${SCHOOL_CLASSES_API}/${classId}/registrations`, {
            params: { yearId: yearId },
         })
         .then(res => res.data);

   //School Year
   const getRegistrations = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(REGISTRATIONS_API);
         setRegistrations(data);
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const getRegistration = (registrationID: string) =>
      axiosPrivate
         .get(`${REGISTRATIONS_API}/getByID/${registrationID}`)
         .then(res => res.data);

   const addRegistration = async (data: TRegistration) => {
      try {
         setRegistrationsLoading(true);
         const res = await axiosPrivate.post(REGISTRATIONS_API, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
         });
         setRegistrations(prev => [...prev, res.data]);
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setRegistrationsLoading(false);
      }
   };
   const editRegistration = async (id: string, data: TRegistration) => {
      if (!id || !data) return {};
      try {
         setRegistrationsLoading(true);
         const res = await axiosPrivate.patch(
            `${REGISTRATIONS_API}/${id}`,
            data,
            {
               headers: { 'Content-Type': 'multipart/form-data' },
            }
         );
         setRegistrations(prev =>
            prev.map(registration =>
               registration._id === res.data?._id ? res.data : registration
            )
         );
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setRegistrationsLoading(false);
      }
   };

   const deleteRegistration = async (id: string) => {
      if (!id) return;
      try {
         await axiosPrivate.delete(`${REGISTRATIONS_API}/${id}`);
         setRegistrations(prev =>
            prev.filter(registration => registration?._id !== id)
         );
         return;
      } catch (err: any) {
         if (!err.response)
            throw new Error(
               'No server response! Check your internet connection.'
            );
         throw new Error(err?.response?.data?.message || false);
      }
   };

   const emailParentsForSignature: TEducationContext['emailParentsForSignature'] =
      registrationID =>
         axiosPrivate
            .get(
               `${REGISTRATIONS_API}/${registrationID}/emailParentsForSignature`
            )
            .then(res => res.data);

   const addAttendance = async (
      date: Date | string,
      absences: TRegistrationResponse['_id'][]
   ) => {
      if (date == null) return { message: 'Please select a date.' };
      if (absences == null || absences.length < 1)
         return { message: 'No student is selected.' };

      try {
         setRegistrationsLoading(true);
         await axiosPrivate.post(`${REGISTRATIONS_API}/attendance`, {
            date,
            absences,
         });
         setRegistrations(prev =>
            prev.map(registration => {
               if (!absences.includes(registration._id)) return registration;
               if (registration.absences.includes(new Date(date).toISOString()))
                  return registration;
               const updatedRegistration = {
                  ...registration,
                  absences: [
                     ...registration.absences,
                     new Date(date).toString(),
                  ],
               };
               return updatedRegistration;
            })
         );
      } catch (err: any) {
         console.log(err);
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setRegistrationsLoading(false);
      }
   };

   //Teachers
   const getTeachers = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(TEACHERS_API);
         setTeachers(data);
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const addTeacher = async (data: TTeacher) => {
      try {
         setTeachersLoading(true);
         const res = await axiosPrivate.post(TEACHERS_API, data, {
            headers: { 'Content-Type': 'application/json' },
         });
         setTeachers(prev => [...prev, res.data]);
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setTeachersLoading(false);
      }
   };
   const editTeacher = async (data: any) => {
      if (!data?._id) return { message: 'Teacher ID is required!' };
      try {
         setTeachersLoading(true);
         const res = await axiosPrivate.patch(
            `${TEACHERS_API}/${data._id}`,
            data,
            {
               headers: { 'Content-Type': 'application/json' },
            }
         );
         setTeachers(prev =>
            prev.map(teacher =>
               teacher._id === res.data._id ? res.data : teacher
            )
         );
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setTeachersLoading(false);
      }
   };

   //Volunteers
   const getVolunteers = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(VOLUNTEERS_API);
         setVolunteers(data);
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const addVolunteer = async (data: any) => {
      try {
         setVolunteersLoading(true);
         const res = await axiosPrivate.post(VOLUNTEERS_API, data, {
            headers: { 'Content-Type': 'application/json' },
         });
         setVolunteers(prev => [...prev, res.data]);
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setVolunteersLoading(false);
      }
   };

   //Sponsors
   const getSponsors = async () => {
      try {
         setLoading(true);
         const { data } = await axiosPrivate.get(SPONSORS_API);
         setSponsors(data);
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setLoading(false);
      }
   };

   const addSponsor = async (data: any) => {
      try {
         setSponsorsLoading(true);
         const res = await axiosPrivate.post(SPONSORS_API, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
         });
         setSponsors(prev => [...prev, res.data]);
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setSponsorsLoading(false);
      }
   };
   const editSponsor = async (id: string, data: any) => {
      if (!id) return { message: 'Sponsor ID is required!' };
      try {
         setSponsorsLoading(true);
         const res = await axiosPrivate.patch(`${SPONSORS_API}/${id}`, data, {
            headers: { 'Content-Type': 'multipart/form-data' },
         });
         setSponsors(prev =>
            prev.map(sponsor =>
               sponsor._id === res.data._id ? res.data : sponsor
            )
         );
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      } finally {
         setSponsorsLoading(false);
      }
   };
   const deleteSponsor = async (id: string) => {
      if (!id) return { message: 'Sponsor ID is required!' };
      try {
         await axiosPrivate.delete(`${SPONSORS_API}/${id}`);
         setSponsors(prev => prev.filter(sponsor => sponsor._id !== id));
         return undefined;
      } catch (err: any) {
         if (!err.response)
            return {
               message: 'No server response! Check your internet connection.',
            };
         return { message: err?.response?.data?.message || false };
      }
   };

   const getStudentsByParent = (parentID: string) =>
      axiosPrivate
         .get(`${PARENTS_API}/getChildrenByParent?parentID=${parentID}`)
         .then(res => res.data);

   const uploadWorksheet = (formData: FormData) =>
      axiosPrivate
         .post(`/api/education/importDocs`, formData)
         .then(res => res.data);

   const downloadClassRosterXLSX: TEducationContext['downloadClassRosterXLSX'] =
      async ({ classId, className, yearId, yearName }) => {
         const res = await axiosPrivate.get(
            `${SCHOOL_CLASSES_API}/exportAsXLSX/students`,
            {
               responseType: 'blob',
               params: { classId, yearId },
            }
         );
         saveAs(res.data, `${className}${yearName ? `_${yearName}` : ''}.xlsx`);
      };

   const downloadEasterVigilReportXLSX: TEducationContext['downloadEasterVigilReportXLSX'] =
      async ({ yearId, yearName }) => {
         const res = await axiosPrivate.get(
            `${DOWNLOAD_API}/education/easter-vigil-report`,
            { responseType: 'blob', params: { yearId } }
         );
         saveAs(
            res.data,
            `Easter Vigil Report${yearName ? `_${yearName}` : ''}.xlsx`
         );
      };

   const downloadConfirmationCert: TEducationContext['downloadConfirmationCert'] =
      async ([classData, settingsData, className, yearName]) => {
         const res = await axiosPrivate.post(
            `${DOWNLOAD_API}/education/confirmation-certificate`,
            { classData, settingsData },
            { responseType: 'blob' }
         );

         saveAs(
            res.data,
            `Confirmation Certificates_${className}${
               yearName ? '_' + yearName : ''
            }.pdf`
         );
      };

   const getConfirmationCertSettings: TEducationContext['getConfirmationCertSettings'] =
      () =>
         axiosPrivate
            .get(`${CHURCHES_API}/confirmationCertificateSettings`)
            .then(res => res.data);

   const updateConfirmationCertSettings: TEducationContext['updateConfirmationCertSettings'] =
      ({ churchName, city, state, pastorName }) =>
         axiosPrivate
            .post(`${CHURCHES_API}/confirmationCertificateSettings`, {
               churchName,
               pastorName,
               city,
               state,
            })
            .then(res => res.data);

   const sendEmailToParents: TEducationContext['sendEmailToParents'] = async ({
      subject,
      content,
      yearId,
      classId,
      file,
   }) => {
      if (!subject) throw new Error("Subject can't be empty!");
      if (!content) throw new Error("Content can't be empty!");
      const formData = new FormData();

      formData.append('subject', subject);
      formData.append('content', content);
      formData.append('yearId', yearId);
      if (classId) formData.append('classId', classId);
      if (file) formData.append('file', file);

      const response = await axiosPrivate.post(
         `${PARENTS_API}/emailToParents`,
         formData,
         {
            headers: { 'Content-Type': 'multipart/form-data' },
         }
      );

      return response.data;
   };

   const getSaintReportRegistrations: TEducationContext['getSaintReportRegistrations'] =
      yearID =>
         axiosPrivate
            .get(`${REGISTRATIONS_API}/saint-report`, { params: { yearID } })
            .then(res => res.data);

   const importStudents: TEducationContext['importStudents'] = formData =>
      axiosPrivate
         .post(`${STUDENTS_API}/import-students`, formData, {
            headers: {
               'Content-Type': 'multipart/form-data',
            },
         })
         .then(res => res.data);

   const verifyStudentImportFile: TEducationContext['verifyStudentImportFile'] =
      formData =>
         axiosPrivate
            .post(`${STUDENTS_API}/verify-student-import`, formData, {
               headers: { 'Content-Type': 'multipart/form-data' },
            })
            .then(res => res.data);

   useEffect(() => {
      let mounted = true;
      if (mounted) {
         getStudents();
         getParents();
         getSchoolYears();
         getCurrentSchoolYear();
         getClasses();
         getRegistrations();
         getTeachers();
         getVolunteers();
         getSponsors();
      }

      return () => {
         mounted = false;
      };
      //eslint-disable-next-line
   }, []);

   return (
      <EducationContext.Provider
         value={{
            loading,
            studentLoading,
            students,
            addStudent,
            editStudent,
            deleteStudentFile,
            parentsLoading,
            parents,
            addParent,
            editParent,
            getStudentsByParent,
            schoolYearLoading,
            schoolYear,
            currentSchoolYear,
            addSchoolYear,
            editSchoolYear,
            getTuitionPaymentMethodStatus,
            connectStripe,
            schoolClassesLoading,
            schoolClasses,
            addSchoolClass,
            editSchoolClass,
            registrationsByClass,
            registrationsLoading,
            registrations,
            getRegistration,
            addRegistration,
            editRegistration,
            deleteRegistration,
            emailParentsForSignature,
            addAttendance,
            teachersLoading,
            teachers,
            addTeacher,
            editTeacher,
            volunteersLoading,
            volunteers,
            addVolunteer,
            sponsorsLoading,
            sponsors,
            addSponsor,
            editSponsor,
            deleteSponsor,
            uploadWorksheet,
            downloadClassRosterXLSX,
            downloadEasterVigilReportXLSX,
            downloadConfirmationCert,
            sendEmailToParents,
            getSaintReportRegistrations,
            importStudents,
            verifyStudentImportFile,
            updateConfirmationCertSettings,
            getConfirmationCertSettings,
         }}
      >
         <Outlet />
      </EducationContext.Provider>
   );
};

export default EducationContext;

export const useEducationContext = () => {
   const context = useContext(EducationContext);
   if (!context) {
      throw new Error(
         'useEducationContext must be used within a EducationProvider'
      );
   }
   return context;
};
